<template>
      
  <div class="all">
    <!-- <aside class="action">
      首页 &nbsp;
      <img src="../../assets/img/InforCenter/juxing14.png" alt="" />
      &nbsp;公共服务 &nbsp;
      <img src="../../assets/img/InforCenter/juxing14.png" alt="" />
      &nbsp;帮助中心
    </aside> -->
    <div class="niupi">
    <mainBox></mainBox>
    <div class="header">帮助中心</div>
    <div class="list">
      <ul class="listed">
        <div class="dev">
          <div class="inputs">
            <input
              type="text"
              placeholder="请输入关键字搜索问题"
              v-model="value"
            />
            <div class="btn" @click="sreach"></div>
          </div>
        </div>
        <li class="null" @click="sreach">搜索</li>
        <li class="nulls" @click="publish">发布意见</li>
      </ul>
    </div>
    <div class="he"></div>
    <div v-if="outList.length != 0">
      <div class="details_list" v-for="(item, key) in outList" :key="key">
        <div class="wen">
          <img src="../../assets/zu79.png" alt="" />
          <span v-html="item.news_title"></span>
        </div>
        <div class="time">
          {{ item.news_time | time }}
        </div>
        <div class="da">
          <!-- <div class="help_imageFloat"> -->
            <img src="../../assets/zu791.png" alt="" />

          <!-- </div> -->
          <span v-html="item.news_content">
          
          </span>
        </div>
      </div>
    </div>

    <div class="allwu" v-else>暂无匹配项~</div>
    <div class="page">
      <Pagetion
        :total="activs"
        :pageSize="5"
        @ClickChange="handleCurrentChange"
      ></Pagetion>
    </div>
    <Footer></Footer>
  </div>
  </div>
</template>

<script>
import Footer from "../../components/footer";
import { fetch } from "../../api/https";
import Pagetion from "../../components/pagetion/pagetion.vue";
import mainBox from "@/components/main.vue";
import moment from "moment";

export default {
  data() {
    return {
      quans: "0",
      outList: [],
      page: 1,
      value: "",
      status: 0,
      activs: 1,
    };
  },
  components: {
    Footer,
    Pagetion,
    mainBox,
  },
  filters: {
    time(num) {
      return moment(1000 * parseInt(num)).format("YYYY-MM-DD HH:mm:SS");
    },
  },
  created() {},
  mounted() {
    this.requstOut();
    fetch("/getmenu").then((res) => {
      document.title = res.data[7].child[0].menu_seo_title;
    });
  },
  methods: {
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.page = val;
      this.requstOut();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    sreach() {
      this.activs = 1;
      this.page = 1;
      this.requstOut();
    },
    publish() {
      this.$router.push("/publish");
    },
    activ(val) {
      this.quans = val;
      this.status = val;
      this.page = 1;
      this.value = "";
      this.requstOut();
    },
    requstOut() {
      fetch("/getFaqList", {
        page: this.page,
        pagesize: 5,
        news_title: this.value,
      })
        .then((res) => {
          this.outList = res.data.data;
          this.activs = res.data.total_count;
          this.outList.forEach((item, key) => {
            var num = (key % 8) + 1;
            var str = `${num}.png`;
            item.ceimg = str;
          });
        })
        .catch((err) => {});
    },
    skipDetails(item) {
      this.$router.push(`/reMen?menuid=${item.id}`);
    },
  },
};
</script>

<style scoped>
.all {
  height: auto;
  width: 100%;
  background-color: rgba(249, 249, 249, 1);
  padding-top: 1px;
}
.action {
  display: block;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  color: #1a1a1a;
  margin-top: 47px !important;
  margin-bottom: 70px !important;
  box-sizing: border-box;
  height: 16px;
  font-family: PingFang SC;
  /* margin-bottom: 10px; */
}
.wen {
  margin-bottom: 11px;
}
.wen > span {
  font-size: 16px;
  color: #666666;
}
.da > div {
  font-size: 20px;
  color: #666666;
  width: 100%;
}
.banner {
  width: 100%;
  height: 80px;
}
.banner img {
  width: 0;
  height: 0%;
}
.header {
  width: 1400px;
  margin: 0 auto !important;
  background: url("../../assets/zu74.png") center;
  text-align: center;
  font-family: Microsoft YaHei;
  height: 24px;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
}
.allwu {
  width: 1400px;
  margin: 0 auto;
  height: 150px;
}
.inputs {
  background: url("../../assets/img/book/search.png");
  background-size: 320px 40px;
  width: 320px;
  float: left;
  height: 42px;
  margin-top: 10px;
  /* float: right; */
  position: relative;
  margin-right: 31px;
}
.inputs input {
  position: absolute;
  left: 0;
  top: 0;
  height: 1px;
  border: none;
  padding-left: 15px;
  height: 16px;
  width: 80% !important;

  background: none;
  outline: none;
  font-size: 14px;
  margin-top: 14px;
}
.btn {
  background: url("../../assets/img/book/searchBtn.png");
  background-size: 16px;
  width: 16px;
  height: 16px;
  float: right;
  text-align: center;
  line-height: 40px;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 7%;
  right: 13px;
}
.listed {
  width: 700px;
  text-align: center;
  margin: 0 auto;
}
.null {
  background: url("../../assets/search.png");
  background-size: 125px 42px;
  color: white;
  font-size: 16px;
  /* margin-top: -10px; */
}
.nulls {
  background: url("../../assets/yj.png");
  background-size: 125px 42px;
  color: white;
  font-size: 16px;
}
.he {
  height: 45px;
}
.list {
  padding-top: 50px;
  width: 1400px;
  margin: 0 auto;
  margin-bottom: 45px;
}
.listed li {
  cursor: pointer;
  height: 42px;
  width: 126px;
  text-align: center;
  line-height: 40px;
  float: left;
  margin-top: 10px;
  /* margin-right: 20px; */
  margin-left: 20px;
}
.details_list {
  /* height: auto; */
  min-width: 226px;
  padding-top: 67px;
  padding-bottom: 70px;
  padding-right: 61px;
  margin: 0 auto;
  width: 1422px;
  background: url("../../assets/tourService/backwen.png") no-repeat;
      background-size: 1422px 100%;
  /* background-color: yellow; */
  padding-left: 100px;
  box-sizing: border-box;
  margin-bottom: 23px;
}
.details_list span {
  font-size: 20px;
  line-height: 35px;
}
.details_list img {
  /* margin-right: 16px; */
  height: 35px;
  width: 35px;
  float: left;
  margin-left: -40px;
}
.time {
  /* padding-left: 51px; */
  width: 187px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #b5b5b5;
  padding-bottom: 20px;
}
.page {
  width: 1400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 78px;
}

.pagination {
  position: absolute;
  right: 30%;
  top: 20px;
}

>>> .el-pagination__total {
  /* margin-top: 6px; */
  font-weight: 600;
  margin-left: 5px;
  /* font-size: 15px; */
  transform: scale(1.19);
}
@media screen and (max-width: 1439px) {
  .header {
    width: 1266px !important;
  }
  .details_list {
    width: 1266px;
    height: auto;
    background-size: 1266px 100%;
    padding-top: 50px;
  }
  .wen div {
    display: block;
    float: left;
    width: 95%;
  }
  .da div {
    display: block;
    line-height: 35px;
    width: 95%;
    float: left;
  }
  .help_imageFloat{
    float:left;
    width: 40px !important;
  }
  .help_imageright{
    float: none !important;
  }
  .page {
    width: 1266px;
  }
  .list {
    width: 1266px;
    margin: 50px auto;
    margin-top: 0px;
  }
  .allwu {
    width: 1266px;
    margin: 0 auto;
  }
}
</style>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #c19c66;
  color: #fff;
}
.el-pagination.is-background .el-pager li.active {
  color: #fff;
  cursor: default;
}
.el-pagination.is-background .el-pager li:hover {
  color: #c19c66;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #c19c66;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #c19c66;
  color: #fff;
}
>>> .el-breadcrumb__inner a:hover,
.el-breadcrumb__inner .is-link:hover {
  color: #303133 !important;
  cursor: pointer;
}
</style>
